import { useState } from 'react'
import MinusIcon from "../../Component/JobGuaranteeDetails/assets/images/Minus.svg"
import PlusIcon from "../../Component/JobGuaranteeDetails/assets/images/Plus.svg"
import './style.scss'

const Collapse = ({ open = false, title, body, className = '' }) => {
  const [isExpanded, setIsExpanded] = useState(open)
  return (
    <div className={`collapse-container ${className}`}>
      <div onClick={() => setIsExpanded(st => !st)} className='collapse-title'>
        <div>{title}</div>
        <img alt="Title is here" src={isExpanded ? MinusIcon : PlusIcon} />
      </div>
      <div className={`collapse-body ${isExpanded ? 'show' : ''}`}>{body}</div>
    </div>
  )
}

export default Collapse
