export const pageType = {
  mentorProfile: "MentorProfile",
  mentorKartPackage: "MentorKartPackage",
  lmsCourses: "LmsCourses",
  videos: "Videos",
};

export const targetUrl = process.env.REACT_APP_URL + `mentorkart?SSO_Mtoken=`;
export const jobsUrl =
  process.env.REACT_APP_JOBPORTAL_URL + `/redirect?SSO_Mtoken=`;
