import {
  filterKeys,
  userCategoryOptions,
  sortByOptions,
  topicsOptions,
} from '../../constants/filterOptions'

const initialFilter = {
  [filterKeys.searchText]: '',
  [filterKeys.price]: 0,
  [filterKeys.currentPage]: 1,
  [filterKeys.userCategory]: userCategoryOptions.find(d => d.default),
  [filterKeys.sortBy]: sortByOptions.find(d => d.default),
  [filterKeys.topic]: topicsOptions.find(d => d.default),
}
const initialState = {
  isLoading: false,
  filter: {
    ...initialFilter,
  },
  filterOptions: {
    [filterKeys.topic]: [topicsOptions.find(d => !!d.default)],
  },
  upcomingSessions: [],
  results: [],
  error: '',
  isFiltered: false,
  totalPages: 0
}

const allUpcomingSessions = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ALL_UPCOMING_SESSIONS':
      return {
        ...state,
        isLoading: true,
        results: [],
      }
    case 'SET_ALL_INITIAL_SESSIONS':
      return {
        ...state,
        upcomingSessions: action.payload.data,
        results: action.payload.data,
        totalPages: action.payload.lastPage,
        isLoading: false,
        isFiltered: false,
      }
    case 'SET_ALL_UPCOMING_SESSIONS':
      return {
        ...state,
        results: action.payload.data,
        totalPages: action.payload.lastPage,
        isLoading: false,
        isFiltered: false,
      }
    case 'SET_UPCOMING_SESSIONS_FILTER_OPTIONS':
      return {
        ...state,
        isLoading: false,
        filterOptions: { ...state.filterOptions, ...action.payload },
      }
    case 'SET_UPCOMING_SESSIONS_FILTER_RESULTS':
      return {
        ...state,
        isFiltered: true,
        filter: { ...state.filter, ...action.payload.filter },
      }

    case 'SET_UPCOMING_SESSIONS_PAGE':
      return {
        ...state,
        filter: { ...state.filter, ...action.payload.filter },
      }
    case 'CLEAR_UPCOMING_SESSIONS_FILTER':
      return {
        ...state,
        filter: { ...state.filter, ...initialFilter },
        results: state.upcomingSessions,
      }
    default:
      return { ...state }
  }
}
export default allUpcomingSessions;