
const initialState = {
    isLoading: false,
    userDetails: {},
    startupImage: ''
}

// const userProfile = handleActions({
//     GET_PROFILE: (state, action) => {
//         return { ...state, userDetails: action.payload }
//     },
//     PROFILE_UPDATED: (state, action) => {
//         return { ...state, isLoading: false }
//     },
//     UPDATE_PROFILE: (state, action) => {
//         return { ...state, isLoading: true }
//     }
// }, initialState)

const userProfile = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PROFILE':
            return { ...state, isLoading: true }
        case 'UPDATE_PROFILE':
            return { ...state, userDetails: action.payload }
        case 'PROFILE_UPDATED':
            return { ...state, isLoading: false }
        default:
            return { ...state }
    }
}

export default userProfile