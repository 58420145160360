const initialState = {
    isLoading: false,
    data: []
}

const modAppointments = (state = initialState, action) => {


    switch(action.type) {
        case 'SET_ALL_APPOINTMENT': {
                return { ...state, data: action.payload }
        }
        default : 
          return {  ...state}
    }
}

export default modAppointments;