import axios from "axios";
import { getAuthHeader } from "../../config/commonAction";
import { GET_USER } from "../../config/endpoints";

export const getUser = () => async dispatch => {
    await axios.get(GET_USER,
        { headers: getAuthHeader() }
    ).then((res) => {
        dispatch({ type: 'SET_USER_DETAILS', payload: res.data.data })
    }).catch((err) => {
        console.log(err)
    })
}