import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import allMentors from '../../pages/Mentors/reducer'
import allCourses from '../../pages/Courses/reducer'
import allPackages from '../../pages/Packages/reducer'
import jobGuaranteeSessions from '../../pages/JobGuaranteeSessions/reducer'
import allUpcomingSessions from '../../pages/UpcomingSessions/reducer'
import authState from '../../Component/Navbar/reducer'
import allAppointments from '../../Component/UpcomingAppointments/reducer'
import mentorInfo from '../../pages/MentorInfo/reducer'
import packageInfo from '../../pages/PackageInfo/reducer'
import courseInfo from '../../pages/CourseInfo/reducer'
import allVideoResourses from '../../Component/VideoResourses/reducer'
import purchaseDetails from '../../Component/MyPurchases/reducer'
import sessionReducer from '../../Component/SessionBooking/reducer'
import orderReducer from '../../Component/ConfirmOrder/reducer'
import userProfile from '../../pages/Profile/reducer'
import { assetData, myLearning } from '../commonReducer'
import globalReducer from '../../Component/GlobalSearch/reducer'
import { getVideoCall } from '../../pages/VideoCall/reducer'
import modAppointments from '../../pages/Moderator/reducer'
import batchDetails from '../../pages/JobGuaranteeEnroll/reducer'


// Reducers
import * as commonReducer from '../commonReducer'

const rootReducer = combineReducers({
    authState,
    packageInfo,
    courseInfo,
    mentorInfo,
    allAppointments,
    jobGuaranteeSessions,
    allUpcomingSessions,
    allPackages,
    allCourses,
    allMentors,
    allVideoResourses,
    purchaseDetails,
    sessionReducer,
    orderReducer,
    modAppointments,
    assetData,
    myLearning,
    userProfile,
    globalReducer,
    getVideoCall,
    batchDetails,
    ...commonReducer,
})

const isDevEnv = process.env.NODE_ENV !== 'production'
const store = createStore(
    rootReducer,
    isDevEnv
        ? composeWithDevTools(applyMiddleware(thunk))
        : applyMiddleware(thunk),
)

export default store
