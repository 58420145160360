const initialState = {
    packages: [],
    video: [],
    user: [],
    course: [],
    contentList: []
}

const globalSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_SEARCH_DATA':
            return { ...state, isLoading: true, coursesRes: action.payload.course, packagesRes: action.payload.packages, videoRes: action.payload.video, userRes: action.payload.user, batch: action.payload.jgpBatch }
        case 'SET_BANNER_CONTENT':
            return { ...state, contentList: action.payload }
        case 'REMOVE_SEARCH_TERM':
            return { ...state, coursesRes: [], packagesRes: [], videoRes: [], userRes: [], batch: [] }
        default:
            return { ...state }
    }
}

export default globalSearchReducer;
