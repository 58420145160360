const initialState = {
    status: false,
    savedAmount: 0,
    code: '',
    Payment: null
}

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DISCOUNT_AMOUNT':
            const { discount_amount: amount = 0, coupon_code: code = '' } = action.payload.data || {};
            return { ...state, status: action.payload.status, savedAmount: amount, code: code }
        case 'SET_PAYMENT_APPOINTMENT':
            return { ...state, Payment: action.payload.payment_url }
        case 'REMOVE_DISCOUNT_DETAIL': {
            return { ...initialState }
        }
        default:
            return { ...state }
    }
}

export default orderReducer;