const initialState = {
  isLoading: false,
  details: {},
  mentors: [],
  courses: [],
  youtube: [],
  error: '',
}

const packageInfo = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_PACKAGE_INFO':
      return { ...state, details: action.payload }
    case 'SAVE_PACKAGE_MENTORS_INFO':
      return { ...state, mentors: action.payload }
    case 'SAVE_COURSES_INFO':
      return { ...state, courses: action.payload }
    case 'SAVE_YOUTUBE_INFO':
      return { ...state, youtube: action.payload }
    default:
      return { ...state }
  }
}

export default packageInfo;
