const initialState = {
    details: {},
    isLoading: false,
    inviteLoading: false,
    error: null,
    participants: []
}

export const getVideoCall = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCHING_CALL_DETAILS':
            return { ...state, isLoading: true }
        case 'SET_VIDEO_CALL_DETAILS':
            return { ...state, details: action.payload.data[0], participants: action.payload.participant, isLoading: false }
        case 'VIDEO_CALL_ERROR':
            return { ...state, error: "Sorry, We couldn't fetch the details for this meeting" }
        case 'SET_INVITE_LOADING':
            return { ...state, inviteLoading: true }
        case 'UNSET_INVITE_LOADING':
            return { ...state, inviteLoading: false }
        case 'REMOVE_CALL_DETAILS':
            return { ...initialState }
        default:
            return { ...state }
    }
}