export const sortByOptions = [
    {
        id: 1,
        label: 'Recommended',
        value: 'recommended',
        showPlaceholder: 'Recommended',
        default: true,
    },
    {
        id: 3,
        label: 'Price',
        value: 'price',
    },
]

export const assetTypeOptions = [
    {
        id: 1,
        label: 'All Asset',
        value: 'all',
        showPlaceholder: 'All Asset',
        default: true,
    },
    {
        id: 2,
        label: 'Mentors',
        value: 'mentors',
    },
    {
        id: 3,
        label: 'Programs and courses',
        value: 'courses',
    },
    {
        id: 4,
        label: 'Upcoming LIVE sessions',
        value: 'upcomingLiveSessions',
    },
    {
        id: 5,
        label: 'Videos and resources',
        value: 'videoResourses',
    },
]

export const userCategoryOptions = [
    {
        id: 1,
        label: 'All',
        value: 'all',
        default: true,
    },
    {
        id: 2,
        label: 'Students',
        value: 'STUDENT',
    },
    {
        id: 3,
        label: 'Professionals',
        value: 'PROFESSIONAL',
    },
    {
        id: 4,
        label: 'Entrepreneurs',
        value: 'ENTREPRENEUR',
    },
]

export const industriesOptions = [
    {
        id: 0,
        label: 'All',
        value: 'all',
        showPlaceholder: 'Industry',
        default: true,
    },
    {
        id: 1,
        label: 'Helth Care',
        value: 'health_care',
    },
    {
        id: 2,
        label: 'Real Estate',
        value: 'real_estate',
    },
    {
        id: 3,
        label: 'Engineering',
        value: 'engineering',
    },
]

export const domainsOptions = [
    {
        id: 0,
        label: 'All',
        value: 'all',
        showPlaceholder: 'Domain',
        default: true,
    },
    {
        id: 1,
        label: 'Web Development',
        value: 'web_evelopment',
    },
    {
        id: 2,
        label: 'App Development',
        value: 'app_development',
    },
    {
        id: 3,
        label: 'Human Resource',
        value: 'human_resource',
    },
    {
        id: 4,
        label: 'Marketing',
        value: 'marketing',
    },
]

export const topicsOptions = [
    {
        id: 0,
        label: 'All',
        value: 'all',
        showPlaceholder: 'Topics',
        default: true,
    },
    {
        id: 1,
        label: 'Web Development',
        value: 'web_evelopment',
    },
    {
        id: 2,
        label: 'Product Manager',
        value: 'product_manager',
    },
    {
        id: 3,
        label: 'Human Resource',
        value: 'human_resource',
    },
    {
        id: 4,
        label: 'Marketing',
        value: 'marketing',
    },
]

export const durationOptions = [
    {
        id: 0,
        label: 'All',
        value: 'all',
        showPlaceholder: 'Time',
    },
    {
        id: 1,
        label: '2 Weeks',
        value: '2',
    },
    {
        id: 2,
        label: '4 Weeks',
        value: '4',
    },
    {
        id: 3,
        label: '6 Weeks',
        value: '6',
    },
    {
        id: 4,
        label: '8+ Weeks',
        value: '8',
    },
]

export const reviewsOptions = [
    {
        id: 0,
        label: 'All',
        value: 'all',
        showPlaceholder: 'Reviews',
    },
]

export const filterKeys = {
    price: 'price',
    searchText: 'searchText',
    userCategory: 'userCategory',
    experience: 'experience',
    sortBy: 'sortBy',
    assetType: 'assetType',
    industry: 'industry',
    domain: 'domain',
    topic: 'topic',
    duration: 'duration',
    review: 'review',
    currentPage : 'currentPage'
}
