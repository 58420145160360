const initialState = {
  isLoading: false,
  details: null,
  packages: null,
  error: '',
}

const mentorInfo = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_MENTOR_INFO':
      return { ...state, details: null, isLoading: true }
    case 'SAVE_MENTOR_INFO':
      return { ...state, details: action.payload }
    case 'SET_MENTORINFO_LOADING_FALSE':
      return { ...state, isLoading: false }
    case 'SAVE_MENTOR_PACKAGE':
      return { ...state, packages: action.payload, isLoading: false }
    case 'CLEAR_MENTOR_INFO':
      return { ...state, details: null }
    case 'REMOVE_MENTOR_DETAIL':
      return { ...initialState }
    default:
      return { ...state }
  }
}

export default mentorInfo;
