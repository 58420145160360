import { useEffect, useState } from 'react'

const useScreenSize = () => {
  const [isMobile, setIsMobile] = useState(false)

  const updateScreenSize = () => {
    if (window.matchMedia('(max-width: 480px)').matches) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    updateScreenSize()
    window.addEventListener('resize', updateScreenSize)
    return () => {
      window.removeEventListener('resize', updateScreenSize)
    }
  }, [])

  return isMobile
}

export const useBigScreenSize = () => {
  const [isBigDevice, setIsBigDevice] = useState(false)

  const updateScreenSize = () => {
    if (window.matchMedia('(max-width: 1024px)').matches) {
      setIsBigDevice(true)
    } else {
      setIsBigDevice(false)
    }
  }

  useEffect(() => {
    updateScreenSize()
    window.addEventListener('resize', updateScreenSize)
    return () => {
      window.removeEventListener('resize', updateScreenSize)
    }
  }, [])

  return isBigDevice
}

export const useMidScreenSize = () => {
  const [isMidDevice, setIsMidDevice] = useState(false)

  const updateScreenSize = () => {
    if (window.matchMedia('(max-width: 912px)').matches) {
      setIsMidDevice(true)
    } else {
      setIsMidDevice(false)
    }
  }

  useEffect(() => {
    updateScreenSize()
    window.addEventListener('resize', updateScreenSize)
    return () => {
      window.removeEventListener('resize', updateScreenSize)
    }
  }, [])

  return isMidDevice
}

export default useScreenSize
