import CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";
import { jobsUrl } from "../constants/redirect";

const WEBSITE_SECRET = process.env.REACT_APP_JOB_SECRET || "397924423F452848";
const appSecrect = Base64.parse(WEBSITE_SECRET);

function base64url(source) {
  let encodedSource = Base64.stringify(source);

  // Remove padding equal characters
  encodedSource = encodedSource.replace(/=+$/, "");
  // Replace characters according to base64url specifications
  encodedSource = encodedSource.replace(/\+/g, "-");
  encodedSource = encodedSource.replace(/\//g, "_");

  return encodedSource;
}

const getUser = () => {
  return JSON.parse(localStorage.getItem("userData")).user;
};

const getRedirectUrl = ({ id, type, role }) => {
  const { email, first_name, last_name, mobile_no } = getUser();

  const header = {
    alg: "HS256",
    typ: "JWT",
  };

  const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  const encodedHeader = base64url(stringifiedHeader);

  const data = {
    email,
    type,
    role,
    domain: "https://app.mentorkart.com",
    first_name,
    last_name,
    mobile_number: mobile_no,
    utm_source: "User profile jobs",
    utm_medium: "app",
  };

  const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
  const encodedData = base64url(stringifiedData);

  const token = encodedHeader + "." + encodedData;

  let signature = CryptoJS.HmacSHA256(token, appSecrect);
  signature = base64url(signature);

  const signedToken = token + "." + signature;
  return `${jobsUrl}${signedToken}`;
};

export default getRedirectUrl;
