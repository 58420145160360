import React from "react"
import { Navigate } from "react-router-dom";

const MentorRoute = ({ children }) => {
    const authed = localStorage.getItem("secretToken");
    const isMentor = JSON.parse(localStorage.getItem("userData"))?.user?.user_type === 'MENTOR'

    return authed ? isMentor ? children : <Navigate to={"/dashboard"} /> : <Navigate to={"/"} />
}

export default MentorRoute;