import {
  filterKeys,
  userCategoryOptions,
  sortByOptions,
  topicsOptions,
} from '../../constants/filterOptions'

const initialFilter = {
  [filterKeys.searchText]: '',
  [filterKeys.price]: 0,
  [filterKeys.currentPage]: 1,
  [filterKeys.userCategory]: userCategoryOptions.find(d => d.default),
  [filterKeys.sortBy]: sortByOptions.find(d => d.default),
  [filterKeys.topic]: topicsOptions.find(d => d.default),
}
const initialState = {
  isLoading: false,
  filter: {
    ...initialFilter,
  },
  filterOptions: {
    [filterKeys.topic]: [topicsOptions.find(d => !!d.default)],
  },
  packages: [],
  results: [],
  error: '',
  totalPages: 0,
  isFiltered: false,
}

const allPackages = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ALL_PACKAGES':
      return {
        ...state,
        isLoading: true,
      }
    case 'SET_ALL_PACKAGES':
      return {
        ...state,
        packages: action.payload.data,
        results: action.payload.data,
        totalPages: action.payload.lastPage,
        isLoading: false,
        isFiltered: false
      }
    case 'PACKAGES_ERROR':
      return { ...state, packages: [], results: [], isLoading: false }
    case 'SET_PACKAGES_FILTER_OPTIONS':
      return {
        ...state,
        filterOptions: { ...state.filterOptions, ...action.payload },
        isLoading: false,
      }
    case 'SET_PACKAGES_FILTER_RESULTS':
      return {
        ...state,
        isFiltered: true,
        filter: { ...state.filter, ...action.payload.filter },
      }
      case 'SET_PACKAGES_PAGE':
      return {
        ...state,
        filter: { ...state.filter, ...action.payload.filter },
      }
    case 'CLEAR_PACKAGES_FILTER':
      return {
        ...state,
        filter: { ...state.filter, ...initialFilter },
        results: state.packages,
      }
    default:
      return { ...state }
  }
}

export default allPackages
