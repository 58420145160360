const initialState = {
    userDetail: {},
    sessionState: false
}

const authState = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_DETAILS':
            return {
                ...state,
                userDetail: action.payload,
                sessionState: true
            }
        default: return { ...state }
    }
}

export default authState;