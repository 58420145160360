const initialState = {
  isLoading: false,
  details: {},
  error: '',
}

const courseInfo = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_COURSE_INFO':
      return { ...state, details: {}, isLoading: true }
    case 'SAVE_COURSE_INFO':
      return { ...state, details: action.payload, isLoading: false }
    case 'CLEAR_COURSE_INFO':
      return { ...state, details: {} }
    default:
      return { ...state }
  }
}

export default courseInfo;
