import {
  filterKeys,
  userCategoryOptions,
  sortByOptions,
  topicsOptions,
} from '../../constants/filterOptions'

const initialFilter = {
  [filterKeys.searchText]: '',
  [filterKeys.price]: 0,
  [filterKeys.userCategory]: userCategoryOptions.find(d => d.default),
  [filterKeys.sortBy]: sortByOptions.find(d => d.default),
  [filterKeys.topic]: topicsOptions.find(d => d.default),
}
const initialState = {
  isLoading: false,
  filter: {
    ...initialFilter,
  },
  filterOptions: {
    [filterKeys.topic]: [topicsOptions.find(d => !!d.default)],
  },
  videoResourses: [],
  results: [],
  error: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ALL_VIDEO_RESOURSES':
      return {
        ...state,
        isLoading: true,
        videoResourses: [],
        results: [],
      }
    case 'SET_ALL_VIDEO_RESOURSES':
      return {
        ...state,
        videoResourses: action.payload.data,
        results: action.payload.data,
        totalPages : action.payload.lastPage,
        isLoading: false,
        isFiltered: false,
      }
    case 'SET_VIDEO_RESOURSES_FILTER_OPTIONS':
      return {
        ...state,
        isLoading: false,
        filterOptions: { ...state.filterOptions, ...action.payload },
      }
    case 'SET_VIDEO_RESOURSES_FILTER_RESULTS':
      return {
        ...state,
        isFiltered : true,
        filter: { ...state.filter, ...action.payload.filter },
      }
    case 'CLEAR_VIDEO_RESOURSES_FILTER':
      return {
        ...state,
        filter: { ...state.filter, ...initialFilter },
        results: state.videoResourses,
      }
    default:
      return { ...state }
  }
}
