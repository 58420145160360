const initialState = {
    isLoading: false,
    batchInfo: {},
    cities: []
}

const batchDetails = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CITY_DATA':
            return { ...state, cities: action.payload }
        case 'GET_BATCH_DETAIL':
            return { ...state, isLoading: true }
        case 'SET_BATCH_DETAIL':
            return { ...state, batchInfo: action.payload, isLoading: false }
        case 'BATCH_DETAIL_ERROR':
            return { ...state, isLoading: false }
        case 'CLEAR_BATCH_INFO':
            return { ...state, batchInfo: {} }
        default:
            return { ...state }
    }
}

export default batchDetails;