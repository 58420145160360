import {
  filterKeys,
  industriesOptions,
  domainsOptions,
  sortByOptions,
  userCategoryOptions,
} from '../../constants/filterOptions'

const initialFilter = {
  [filterKeys.searchText]: '',
  [filterKeys.price]: 0,
  [filterKeys.experience]: 0,
  [filterKeys.currentPage]: 1,
  [filterKeys.industry]: industriesOptions.find(d => !!d.default),
  [filterKeys.domain]: domainsOptions.find(d => !!d.default),
  [filterKeys.sortBy]: sortByOptions.find(d => d.default),
  [filterKeys.userCategory]: userCategoryOptions.find(d => d.default),
}

const initialState = {
  isLoading: false,
  filterOptions: {
    [filterKeys.industry]: [industriesOptions.find(d => !!d.default)],
    [filterKeys.domain]: [domainsOptions.find(d => !!d.default)],
  },
  filter: {
    ...initialFilter,
  },
  mentors: [],
  results: [],
  industries: [],
  domains: [],
  error: '',
  totalPages: 0,
  isFiltered: false,
  totalMentors: 0
}
const allMentors = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MENTORS_LOADING':
      return { ...state, isLoading: true }
    case 'SET_ALL_MENTORS':
      return {
        ...state,
        mentors: action.payload.data,
        results: action.payload.data,
        totalPages: action.payload.lastPage,
        totalMentors: action.payload.total,
        isLoading: false,
        isFiltered: false
      }
    case 'SET_MENTORS_FILTER_OPTIONS':
      return {
        ...state,
        filterOptions: { ...state.filterOptions, ...action.payload },
        isLoading: false,
      }
    case 'SET_MENTORS_FILTER_RESULTS':
      return {
        ...state,
        isFiltered: true,
        filter: { ...state.filter, ...action.payload.filter },
      }
      case 'SET_MENTORS_PAGE':
      return {
        ...state,
        filter: { ...state.filter, ...action.payload.filter },
      }
    case 'CLEAR_MENTORS_FILTER':
      return {
        ...state,
        filter: { ...state.filter, ...initialFilter },
        results: state.mentors,
      }
    case 'SET_INDUSTRY_BY_MENTOR':
      return {
        ...state,
        industries: action.payload.data.industry,
        domains: action.payload.data.domains
      }
    default:
      return { ...state }
  }
}

export default allMentors
