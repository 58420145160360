import React from "react"
import { Navigate } from "react-router-dom";

const ModeratorRoute = ({ children }) => {
    const authed = localStorage.getItem("secretToken");
    const isModerator = JSON.parse(localStorage.getItem("userData"))?.user?.email === 'mentorkart@mentorkart.com'

    return authed ? isModerator ? children : <Navigate to={"/dashboard"} /> : <Navigate to={"/"} />
}

export default ModeratorRoute;