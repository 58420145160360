const initialState = {
    isLoading: false,
    timeSlots: [],
    availableDates: []
}

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOADING_MENTOR_AVAILABILITY':
            return { ...state, isLoading: true }
        case 'SET_MENTOR_AVAILABILITY':
            return { ...state, availableDates: action.payload.availableDate, timeSlots: action.payload.time_slot, isLoading: false }
        default:
            return { ...state }
    }
}
export default sessionReducer;