import React from "react"
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
    const authed = localStorage.getItem("secretToken");
    const isMentor = JSON.parse(localStorage.getItem("userData"))?.user?.user_type === 'MENTOR'
    const isModerator = JSON.parse(localStorage.getItem("userData"))?.user?.email === 'mentorkart@mentorkart.com'

    return authed ? isModerator ? <Navigate to={"/moderator"} />  : !isMentor ? children : <Navigate to={"/mentor"} /> : <Navigate to={"/"} />

}

export default PrivateRoute;