
const initialState = {
    purchaseDetails: [],
    isLoaded: false
}

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PURCHASE_DETAILS':
            return {
                ...state,
                purchaseDetails: action.payload,
                isLoaded: true
            }
            default:
            return { ...state }
    }
}

export default orderReducer;
