const initialState = {
    appointments: [],
    isLoaded: false
}

const allAppointments = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ALL_APPOINTMENTS':
            return { ...state, appointments: action.payload, isLoaded: true }
        default:
            return { ...state }
    }
}

export default allAppointments;